<template>
  <div>
      <van-grid :column-num="1">
		<van-grid-item :dot="subject.categoryId === '102'" @click="$emit('click', ['categoryId', '102'])">
			<template #icon>
				<!-- <van-badge content="VIP"> -->
					<img height="50" src="@/assets/vip-home/cx_c1c2c3.png" alt="小车">
				<!-- </van-badge> -->
			</template>	
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '104'" @click="$emit('click', ['categoryId', '104'])">
			<template #icon>
				<!-- <van-badge content="VIP"> -->
				<img height="50" src="@/assets/vip-home/cx_a2b2.png" alt="货车">
				<!-- </van-badge> -->
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '105'" @click="$emit('click', ['categoryId', '105'])">
			<template #icon>
				<img height="50" src="@/assets/vip-home/cx_a1b1a3.png" alt="客车">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '106'" @click="$emit('click', ['categoryId', '106'])">
			<template #icon>
				<img height="50" src="@/assets/vip-home/cx_def.png" alt="摩托车">
			</template>
		</van-grid-item>
	</van-grid>
	<!-- <van-grid column-num="2">
		<van-grid-item text="科目一" :dot="subject.subject === '1'" @click="$emit('click', ['subject', '1'])">
			<template #icon>
				<img width="100" src="@/assets/svg/km1.svg" alt="">
			</template>
		</van-grid-item>
		<van-grid-item text="科目四" :dot="subject.subject === '4'" @click="$emit('click', ['subject', '4'])">
			<template #icon>
				<img width="100" src="@/assets/svg/km4.svg" alt="">
			</template>
		</van-grid-item>
	</van-grid> -->
  </div>
</template>

<script>
export default {
    props: ['subject'],
};
</script>

<style>
</style>
